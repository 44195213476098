import React from 'react';

class LastCheckedPlate extends React.Component {
	render() {
		return (
			<div className="lastCheckedPlate">
				<span className="info">Laatst controleerde nummerplaat: {this.props.plate} (tussentijd: {this.props.checkInterval/1000} seconden)</span>
			</div>
		);
	}
}

export default LastCheckedPlate;