import React from 'react';
import moment from 'moment'
import 'moment/locale/nl'
moment.locale('nl')

class LastRegisteredPlate extends  React.Component {
	render() {
		var humanCheckedTime = moment(this.props.lastCheckedTime).format('dddd D MMMM') + ' om ' + moment(this.props.lastCheckedTime).format('HH:mm');
		var timeSinceLastChecked = Date.now() - this.props.lastCheckedTime;
		var oneHour = 60 * 60 * 1000;
		return (
			<div className="last-registerd-plate-fullscreen-wrapper">
				<div className="last-registerd-plate">
					<div className="info">Laatst geregistreerde nummerplaat:</div>
					<div className="plate">{this.props.plate}</div>
					{this.props.numberOfRegisteredPlatesToday !== undefined && this.props.numberOfRegisteredPlatesToday !== null && <div className="info number-of-registered-plates-today">Er zijn vandaag al {this.props.numberOfRegisteredPlatesToday} nummerplaten geregistreerd.</div>}
					{this.props.lastCheckedTime !== undefined && this.props.lastCheckedTime !== null && this.props.lastCheckedTime !== 0 && timeSinceLastChecked > oneHour && <div className="info last-checked-time">Laatste update: {humanCheckedTime}</div>}
				</div>
			</div>
		);
	}
}

export default LastRegisteredPlate;