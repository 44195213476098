import React from 'react';
import {
  FacebookShareButton,
  FacebookIcon,
  PinterestShareButton,
  PinterestIcon,
  TwitterShareButton,
  TwitterIcon
} from "react-share";

class SocialShareButtons extends React.Component {
  render() {
    return (
      <div className="social-share-buttons">
        <FacebookShareButton url={this.props.shareUrl}><FacebookIcon size={32} round={true} /></FacebookShareButton>
        <PinterestShareButton url={this.props.shareUrl} media={this.props.mediaUrl}><PinterestIcon size={32} round={true} /></PinterestShareButton>
        <TwitterShareButton url={this.props.shareUrl}><TwitterIcon size={32} round={true} /></TwitterShareButton>
      </div>
    );
  }
}

export default SocialShareButtons;